import React, { lazy, Suspense, useState } from 'react';
import { Routes, Route, } from 'react-router-dom'


const App = lazy(() => import('../App'))
const NotFound = lazy(() => import('./not_found'));


const Landing = () => {

    const [oldUrl, setOldUrl] = useState(window.location.href)

    // При смене url пути сайта в Яндекс Метрику отправляется запрос о новом адресе
    React.useEffect(() => {
        const newurl = window.location.href
        if (newurl !== oldUrl) {
            window.ym(88034166, 'hit', window.location.href)
            setOldUrl(newurl)
        }
    }, [oldUrl])

    return (
        <div>

            <Routes>

                {/* Главный экран */}
                <Route path='/' element={
                    <Suspense fallback={<div />}> <App /></Suspense>
                } />

                {/* Роут неопознанного url адреса */}



                <Route path='*' hasErrorBoundary status={404} render={({ staticContext }) => {
                    if (staticContext)
                        staticContext.status = 404
                }} element={
                    <Suspense fallback={<div />}>
                        <NotFound />
                    </Suspense>} />






            </Routes>
        </div>

    )

}

export default Landing