
import React from 'react';
import './index.scss';
import Landing from './components/Landing';
import reportWebVitals from './reportWebVitals';
import { hydrate, render } from "react-dom";
import { BrowserRouter } from 'react-router-dom'


const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <BrowserRouter>
      <Landing />
    </BrowserRouter>
    , rootElement);
} else {
  render(
    <BrowserRouter>
      <Landing />
    </BrowserRouter>, rootElement);

}
// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(<React.StrictMode>
//   <App />
// </React.StrictMode>)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
